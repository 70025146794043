<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.rejectSell.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="desc" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.rejectSell.title') }}
        </div>
        <div class="date--time">
          {{ $t('booking.modal.rejectSell.message') }}
        </div>
        <hr class="separator" />
        <div class="d-flex justify-space-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-danger main--btn" @click="reject">
            {{ buttonName }}
          </div>
          <!--          <div class="btn btn-primary main&#45;&#45;btn" @click="close">-->
          <!--            Terima Permintaan Survei-->
          <!--          </div>-->
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'sell-reject-modal',
  components: { SectionLoader },
  data() {
    return {
      modalName: 'sell-reject-modal',
      loading: false,
      buttonName: '',
      uuid: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.uuid = event.params.uuid;
      this.buttonName = event.params.buttonName;
    },
    async reject() {
      this.loading = true;
      try {
        let data = await this.$store.dispatch('sellTransaction/cancel', this.uuid);
        if (data) {
          this.$emit('actionDone');
          this.close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.sellTransaction.rejectedMsg'),
            'success',
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
